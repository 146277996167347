import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert,
  Modal,
  Collapse,
} from "reactstrap"
import Dropzone from "react-dropzone"

import classnames from "classnames"
import { Link } from "react-router-dom"
import axiosInstance from "service/axiosConfig"
import "./TalentPoolEvaluation.css"
import img from "../../assets/images/Kiru-img-1.jpg"

import { useSelector, useDispatch } from 'react-redux'
import { addResume, setIsOpen, setInitState } from "redux/slices/resumeSlice"


const FormWizard = () => {
  //meta title
  document.title = "Talent Pool | R24.ai"

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [isFormValid, setIsFormValid] = useState(false)
  const [continueClicked, setContinueClicked] = useState(false)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        if (tab === 2) SetIsSubmit(true)
        else SetIsSubmit(false)
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function tog_result() {
    SetResultantModal(!resultant_modal);
    document.body.classList.add("no_padding");
  }

  const [jobTitle, setJobTitle] = useState("")
  const [jobDescription, setJobDescription] = useState("")
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [isSubmit, SetIsSubmit] = useState(false)
  const [isSubmitClicked, SetIsSubmitClicked] = useState(false);
  const [error, setError] = useState([]);
  const [totalProgress, SetTotalProgress] = useState(0);
  const [progressInNumbers, SetProgressInNumbers] = useState(0);
  const [resultant_modal, SetResultantModal] = useState(false);

  const [responseHeader, setResponseHeader] = useState([
    "Candidate Name",
    "Education",
    "Projects",
    "Relevancy",
    "Technical Skills",
    "Overall Score",
  ])

  const resume = useSelector((state) => state.resume.resumeResponse)
  const dispatch = useDispatch()

  const handleFileEvent = files => {
    const chosenFiles = files
    const pdfFiles = chosenFiles.filter(file => file.type === "application/pdf")
    if (pdfFiles.length !== chosenFiles.length) {
      alert("Only PDF files are allowed.")
    } else {
      var newuploads = pdfFiles.filter(file => {
        return !uploadedFiles.some(upload => upload.name === file.name)
      })
      if (newuploads.length > 0) {
        var uploads = [...uploadedFiles].concat(newuploads)
        setUploadedFiles(uploads);
      }
    }
  }
  const handleRemoveFile = index => {
    const updatedFiles = uploadedFiles.filter((file, i) => i !== index)
    setUploadedFiles(updatedFiles);
  }

  const handleJobTitleInputChange = event => {
    setJobTitle(event.target.value)
    setIsFormValid(event.target.form.checkValidity())
  }

  const handleJobDescriptionInputChange = event => {
    setJobDescription(event.target.value)
    setIsFormValid(event.target.form.checkValidity())
  }

  async function Submit() {
    setError([])
    SetIsSubmitClicked(true);
    if (uploadedFiles.length > 0 && jobTitle !== "" && jobDescription !== "") {
      SetTotalProgress(uploadedFiles.length);
      SetProgressInNumbers(0);
      let progressIndex = 0;
      dispatch(setInitState()) // set resumes to initial state
      SetResultantModal(true);
      uploadedFiles.forEach((file) => {
        const formData = new FormData()
        formData.append("title", jobTitle)
        formData.append("jobDescription", jobDescription)
        formData.append("pdf_files", file, file.name)
        axiosInstance
          .post("/api/submit_job/", formData)
          .then(response => {
            SetProgressInNumbers(++progressIndex);
            var resp = response.data
            var response = JSON.parse(resp.result_json)
            if (resume.length === 0) {
              response["open"] = true;
            } else {
              response["open"] = false;
            }
            dispatch(addResume(response))
          })
          .catch(error => {
            setIsLoading(false)
            if (error.hasOwnProperty("response")) {
              if (error.response.status === 500) {
                setError(["Server Error"])
                return
              }
              var errorList = error.response.data
              var finalResponse = []
              if (errorList.hasOwnProperty("title")) {
                let titleVal = `Title: ${errorList.title[0]}`
                finalResponse.push(titleVal)
              }
              if (errorList.hasOwnProperty("jobDescription")) {
                let jdVal = `JobDescription: ${errorList.jobDescription[0]}`
                finalResponse.push(jdVal)
              }
              if (errorList.hasOwnProperty("pdf_files")) {
                let pfVal = `Browse Files: ${errorList.pdf_files[0]}`
                finalResponse.push(pfVal)
              }
              if (errorList.hasOwnProperty("error"))
                finalResponse.push(errorList.error)
              if (typeof errorList === "string") finalResponse.push(errorList)
              setError(finalResponse)
            } else {
              setError([error.message])
            }
          })
      })
    }
  }

  const createNewJob = () => {
    const formData = new FormData()
    formData.append("title", jobTitle)
    formData.append("job_description", jobDescription)
    axiosInstance
      .post("/jobs/create_new_job", formData)
      .then(response => {
        console.log("Response from server: ", response)
      })
      .catch(error => {
        console.log("Error: ", error)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h1 className="card-title mb-4 text-center">
                    AI Resume Matcher
                  </h1>
                  <div className="wizard clearfix">
                    {/* Stepper Start */}
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({
                              current: activeTab === 1,
                            })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span
                              className="number"
                              style={{
                                height: "10px",
                                width: "10px",
                              }}
                            ></span>
                            1. Enter Job Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTab === 2,
                            })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span
                              className="number"
                              style={{
                                height: "10px",
                                width: "10px",
                              }}
                            ></span>
                            2. Attach Resume
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    {/* Stepper End */}
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <Row>
                                  <div className="mb-3 py-2">
                                    <Label for="jobTitle">
                                      Job Title
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      id="jobTitle"
                                      name="jobTitle"
                                      value={jobTitle}
                                      onChange={handleJobTitleInputChange}
                                      type="text"
                                      className="form-control"
                                      required
                                    />
                                    {(continueClicked || isSubmitClicked) && !jobTitle && (
                                      <div className="text-danger">
                                        Job Title is required
                                      </div>
                                    )}
                                  </div>
                                </Row>
                                <Row>
                                  <div className="mb-3 py-2">
                                    <Label for="jobDescription">
                                      Job Description
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <textarea
                                      id="jobDescription"
                                      name="jobDescription"
                                      value={jobDescription}
                                      onChange={
                                        handleJobDescriptionInputChange
                                      }
                                      className="form-control"
                                      rows="14"
                                      required
                                    />
                                    {(continueClicked || isSubmitClicked) && !jobDescription && (
                                      <div className="text-danger">
                                        Job Title is required
                                      </div>
                                    )}
                                  </div>
                                </Row>
                              </Col>
                              <Col lg="6">
                                <img
                                  style={{
                                    width: "-webkit-fill-available",
                                  }}
                                  src={img}
                                ></img>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <label>
                                    Attach Resume
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className={uploadedFiles.length > 0 ? 'uploaded-files my-2' : 'uploaded-files'}>
                                    {uploadedFiles.map((file, index) => (
                                      <div key={index} className="pill">
                                        {file.name}
                                        <span
                                          className="remove-pill"
                                          onClick={() =>
                                            handleRemoveFile(index)
                                          }
                                        >
                                          &#x2715;
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                  <Dropzone onDrop={handleFileEvent}>
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick mt-2"
                                          {...getRootProps()}
                                        >
                                          <h3 className="text-center">
                                            Upload your Resume
                                          </h3>
                                          <input {...getInputProps()} required />
                                          <div className="mb-3">
                                            <i className="display-4 text-primary bx bxs-cloud-upload" />
                                          </div>
                                          <h4>
                                            Drag and Drop your file in this
                                            box.
                                          </h4>
                                          <h5 className="text-center">
                                            Accepted File Types:
                                          </h5>
                                          <h5>PDF Only.</h5>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  {isSubmitClicked && uploadedFiles.length == 0 && (
                                    <div className="text-danger">
                                      Please attach minimum one resume to analyze.
                                    </div>
                                  )}
                                </Col>
                                <Col lg="6">
                                  <img
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                    src={img}
                                  ></img>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul className="text-center">
                        <li
                          className={
                            activeTab === 1
                              ? "previous disabled d-none"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;Back&nbsp;&nbsp;&nbsp;
                          </Link>
                        </li>
                        <li className={!isSubmit ? "next" : "d-none"}>
                          <Link
                            to="#"
                            onClick={() => {
                              if (isFormValid) {
                                // Add create new job logic
                                createNewJob()
                                toggleTab(activeTab + 1)
                              } else {
                                setContinueClicked(true)
                              }
                            }}
                            disabled={!isFormValid}
                          >
                            &nbsp;Continue&nbsp;
                          </Link>
                        </li>
                        <li className={isSubmit ? "next" : "d-none"}>
                          <Link to="#" className="w-50" onClick={Submit}>
                            &nbsp;Submit&nbsp;
                          </Link>
                        </li>
                        {resume.length ? (
                          <li className="next">
                            <Link to="#" className="w-50" onClick={tog_result}>
                              &nbsp;View Result&nbsp;
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                  {error.map((item, index) => (
                    <Alert key={index} color="danger">
                      {item}
                    </Alert>
                  ))}
                  {/* Start Results */}
                  <Modal
                    scrollable={true}
                    size="xl"
                    isOpen={resultant_modal}
                    toggle={() => {
                      tog_result();
                    }}
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="result_details_modal"
                      >
                        AI Result
                      </h5>
                      <button
                        onClick={() => {
                          tog_result();
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {/* Progress Status */}
                      {totalProgress > 0 ? (
                        <span>
                          {(progressInNumbers !== totalProgress) ? (
                            <i className="bx bx-loader-circle bx-spin"></i>
                          ) : null}
                          {progressInNumbers}/{totalProgress} Completed.
                          {(progressInNumbers === totalProgress) ? (
                            <span className="bx bxs-badge-check text-primary"></span>
                          ) : null}
                        </span>
                      ) : null}
                      {resume.length ? (
                        <div className="accordion" id="accordion">
                          {resume.map((item, index) => (
                            <div key={index} className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !item.open }
                                  )}
                                  type="button"
                                  onClick={() => dispatch(setIsOpen(index))}
                                  style={{ cursor: "pointer" }}
                                >
                                  Analysis of {item.candidate_name}
                                </button>
                              </h2>
                              <Collapse isOpen={item.open} className="accordion-collapse">
                                <div className="accordion-body">
                                  <div className="table-responsive">
                                    <table className="table table-bordered mb-0 table">
                                      <thead>
                                        <tr>
                                          {responseHeader.map(h => (
                                            <td key={h}>{h}</td>
                                          ))}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td key={index + ".1"}>
                                            {item.candidate_name}
                                          </td>
                                          <td key={index + ".2"}>{item.education}</td>
                                          <td key={index + ".3"}>{item.projects}</td>
                                          <td key={index + ".5"}>{item.relevancy}</td>
                                          <td key={index + ".4"}>
                                            {item.technical_skills}
                                          </td>
                                          <td key={index + ".6"}>{item.overall_score}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <p className="my-2">
                                      <strong>Summary: </strong> {item.analysis_summary}
                                    </p>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_result();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/* <button
                        type="button"
                        className="btn btn-primary "
                      >
                        Save changes
                      </button> */}
                    </div>
                  </Modal>
                  {/* End Results */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormWizard
