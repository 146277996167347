// src/components/BetaBadge.js
import React from 'react';
import './BetaBadge.css'; // Create this CSS file for styling

const BetaBadge = () => {
  return (
    <div className="beta-badge">
      Beta
    </div>
  );
};

export default BetaBadge;
