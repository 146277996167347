import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Tooltip,
} from "reactstrap"

import axiosInstance from "service/axiosConfig"
import logo from "../../assets/images/r24-logo.svg"

const AddNewJobModal = ({ toggle, displaySuccessMessage, modal, redirectFn }) => {
  const [jobTitle, setJobTitle] = useState("")
  const [jobDescription, setJobDescription] = useState("")
  const [isFormValid, setIsFormValid] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [focusedField, setFocusedField] = useState(null)
  const navigate = useNavigate()

  const [tooltipOpen1, setTooltipOpen1] = useState(false)
  const [tooltipOpen2, setTooltipOpen2] = useState(false)

  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1)
  const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2)

  const handleJobTitleInputChange = event => {
    setJobTitle(event.target.value)
    setIsFormValid(event.target.form.checkValidity())
  }

  const handleJobDescriptionInputChange = event => {
    setJobDescription(event.target.value)
    setIsFormValid(event.target.form.checkValidity())
  }

  const createNewJob = () => {
    const formData = new FormData()
    formData.append("title", jobTitle)
    formData.append("job_description", jobDescription)
    axiosInstance
      .post("/jobs/create_new_job", formData)
      .then(response => {
        const data = response.data;
        const job = data?.job;
        setJobTitle("")
        setJobDescription("")
        setSubmitAttempted(false)
        toggle()
        displaySuccessMessage()
        if (job) {
          redirectFn(job?.guid, job?.title);
        }
      })
      .catch(error => {
        console.log("Error: ", error)
      })
  }

  const handleSubmit = () => {
    setSubmitAttempted(true) // Mark that a submit attempt has been made

    // Only create a new job if the form is valid
    if (isFormValid) {
      createNewJob()
    }
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      style={{ maxWidth: "600px", margin: "100px auto" }}
      contentClassName="rounded" // provides rounded edges to the modal
    >
      {/* <ModalHeader toggle={toggle}>Add New Job</ModalHeader> */}
      <i
        className="fas fa-times fa-lg position-absolute top-0 end-0 m-2"
        style={{ zIndex: 2, cursor: "pointer" }}
        onClick={toggle}
      ></i>
      <ModalBody className="p-3">
        <Card
          style={{
            borderRadius: "20px",
            // boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            margin: "12px",
          }}
        >
          <CardBody style={{ padding: "20px" }}>
            {/* Logo Placeholder */}
            <div className="text-center">
              <img
                src={logo}
                alt="Logo"
                className="mb-2"
                style={{ maxWidth: "50px" }}
              />
            </div>

            {/* Title */}
            <h2
              className="mb-3"
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                marginTop: "8px",
                textAlign: "center",
                color: "black",
              }}
            >
              Create a New Job
            </h2>
            <p className="text-muted mb-4 text-center">
              Every job journey begins with a posting. Start yours by adding a clear job title and a job description to attract the right talent.
            </p>

            <Form>
              {/* Job Title */}
              <Label for="jobTitle">
                Job Title <span className="text-danger">*</span>{" "}
              </Label>
              <FormGroup className="mb-4">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    id="jobTitle"
                    name="jobTitle"
                    value={jobTitle}
                    onChange={handleJobTitleInputChange}
                    type="text"
                    placeholder="e.g., Software Developer"
                    required
                    style={{
                      borderRadius: "10px",
                      border:
                        focusedField === "jobTitle"
                          ? "2px solid #007BFF"
                          : "1px solid #ced4da", // Update border based on focus
                    }}
                    onFocus={() => setFocusedField("jobTitle")} // Set focusedField to 'jobTitle' when focused
                    onBlur={() => setFocusedField(null)} // Reset focusedField when blurred
                  />

                  {/* <span
                    id="jobTitleTooltip"
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  >
                    <i id="jobTitleIcon" className="fas fa-info-circle"></i>
                  </span> */}
                </div>

                {submitAttempted && !jobTitle && (
                  <div className="text-danger">Job Title is required</div>
                )}
              </FormGroup>

              {/* <Tooltip
                placement="top"
                isOpen={tooltipOpen1}
                target="jobTitleTooltip"
                toggle={toggleTooltip1}
                fade={false}
              >
                Provide a clear and concise job title.
              </Tooltip> */}

              {/* Job Description */}
              <Label for="jobDescription">
                Job Description<span className="text-danger">*</span>{" "}
              </Label>
              <FormGroup className="mb-4">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    id="jobDescription"
                    name="jobDescription"
                    value={jobDescription}
                    onChange={handleJobDescriptionInputChange}
                    type="textarea"
                    rows="5"
                    placeholder="Briefly describe the responsibilities and qualifications..."
                    required
                    style={{
                      borderRadius: "10px",
                      border:
                        focusedField === "jobDescription"
                          ? "2px solid #007BFF"
                          : "1px solid #ced4da", // Update border based on focus
                    }}
                    onFocus={() => setFocusedField("jobDescription")} // Set focusedField to 'jobDescription' when focused
                    onBlur={() => setFocusedField(null)} // Reset focusedField when blurred
                  />

                  {/* <span
                    id="jobDescriptionTooltip"
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  >
                    <i
                      id="jobDescriptionIcon"
                      className="fas fa-info-circle"
                    ></i>
                  </span> */}
                </div>
                {submitAttempted && !jobDescription && (
                  <div className="text-danger">Job Description is required</div>
                )}
              </FormGroup>

              {/* <Tooltip
                placement="right"
                isOpen={tooltipOpen2}
                target="jobDescriptionIcon"
                toggle={toggleTooltip2}
                fade={false}
              >
                Describe the main tasks and qualifications required for this
                job.
              </Tooltip> */}

              {/* Buttons */}
              <div className="d-flex justify-content-between">
                <Button
                  outline
                  color="secondary"
                  onClick={toggle}
                  className="mr-2"
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "10px 18px",
                    fontSize: "0.8rem",
                    transition: "all 0.3s ease",
                  }}
                  onMouseOver={e => {
                    e.target.style.backgroundColor = "#88A6F2"
                    e.target.style.color = "#FFFFFF"
                  }}
                  onMouseOut={e => {
                    e.target.style.backgroundColor = "transparent"
                    e.target.style.color = "black"
                  }}
                >
                  Cancel
                </Button>
                <Button
                  outline
                  color="primary"
                  className="ml-2 btn btn-primary"
                  onClick={handleSubmit}
                  style={{
                    color: "white",
                    fontWeight: "500",
                    padding: "10px 18px",
                    fontSize: "0.8rem",
                    transition: "all 0.3s ease",
                  }}
                  onMouseOver={e => {
                    e.target.style.backgroundColor = "#B3CBFF"
                    e.target.style.color = "black"
                  }}
                  onMouseOut={e => {
                    e.target.style.backgroundColor = "#556ee6"
                    e.target.style.color = "white"
                  }}
                >
                  Create New Job
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}

export default AddNewJobModal
