// src/components/filter.
import React, { useState, useEffect, useMemo } from "react"
import useDebounce from "../../components/Common/addDelay"
import { useNavigate } from "react-router-dom"

import { Badge, Card, CardBody, Col, Input, Row } from "reactstrap"

//import components
import JobTableContainer from "../../components/Common/JobTableContainer"

import axiosInstance from "service/axiosConfig"
import Loader from "components/Common/loader"
import AddNewJobModal from "../../components/Common/AddNewJobModal";

function JobsTable() {
  const navigate = useNavigate();
  const [isLoading, SetIsLoading] = useState(false);
  const [data, setData] = useState([]) // State for storing the data records
  const [totalPages, setTotalPages] = useState(0) // State for storing total pages
  const [totalRecords, setTotalRecords] = useState(0) // display the total count of records
  const [page, setPage] = useState(1) // Current page
  const [pageSize, setPageSize] = useState(10) // Records per page
  const [searchQuery, setSearchQuery] = React.useState("")
  const debouncedSearchQuery = useDebounce(searchQuery, 1000)
  const [sortBy, setSortBy] = useState("id")
  const [sortOrder, setSortOrder] = useState("desc")
  const [statusFilter, setStatusFilter] = useState("All");
  const [addNewJobModal, setAddNewJobModal] = useState(false);
  const [totalJobsCount, setTotalJobsCount] = useState(null)
  const [refreshData, setRefreshData] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
    setPage(1)
  }
  const clearSearch = () => {
    setSearchQuery('');
  };

  const displaySuccessMessage = () => {
    // console.log("Displaying success message")
    setShowSuccessMessage(true)
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 5000)
  }

  const toggle = () => {
    setAddNewJobModal(!addNewJobModal)
  }

  const redirectToViewCandidate = (guid, title) => {
    navigate(`/view_candidates/${guid}`, {
      state: { jobTitle: title }
    });
  }

  useEffect(() => {
    SetIsLoading(true);
    axiosInstance
      .get(
        `jobs/list_all_jobs?page=${page}&pageSize=${pageSize}&search=${debouncedSearchQuery}&sortBy=${sortBy}&sortOrder=${sortOrder}&statusFilter=${statusFilter}`
      )
      .then(response => {
        setData(response.data.records)
        setTotalPages(response.data.totalPages)
        setTotalRecords(response.data.total_records)
        setTotalJobsCount(response.data.jobs_count)
        SetIsLoading(false);
      })
      .catch(error => {
        SetIsLoading(false);
        console.error("There was an error fetching the jobs", error)
      })
  }, [page, pageSize, debouncedSearchQuery, sortBy, sortOrder, statusFilter, addNewJobModal, refreshData])

  const transformedData = data?.map(job => ({
    id: job.guid,
    title: job.title,
    jobDescription: job.job_description,
    createdAt: job.created_at,
    resumeCount: job.candidate_count,
    status: job.status,
  }))

  const columns = useMemo(
    () => [
      {
        Header: "Job",
        accessor: "title",
      },
      {
        Header: "Applicants",
        accessor: "candidate_count",
      },
      {
        Header: "Created",
        accessor: "created_at",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  )

  document.title = "R24"

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="View All Jobs" breadcrumbItem="Data Tables" /> */}

        <Card className="mt-2">
          <CardBody>
            {toggle && (
              <AddNewJobModal
                toggle={toggle}
                displaySuccessMessage={displaySuccessMessage}
                modal={addNewJobModal}
                redirectFn={redirectToViewCandidate}
              />
            )}
            {showSuccessMessage && (
              <div className="alert alert-success">Job created successfully!</div>
            )}
            {(data.length > 0 || searchQuery) && (
              <>
                <Row className="mb-4">
                  <Col xs="12" md="6" className="d-flex align-items-center">
                    <h2 style={{ fontWeight: "bold" }}>Jobs</h2>
                    <h3 style={{ fontWeight: "bold" }} className="mx-2">
                      <Badge color="light">{totalRecords}</Badge>
                    </h3>
                  </Col>
                </Row>

                <div
                  className="d-flex gap-2"
                  style={{
                    height: "40px",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="d-flex gap-3">
                    <div className="row">
                      <div className="col-md-9">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          style={{
                            width: "250px",
                            paddingRight: "30px", // Ensure space for the clear button
                          }}
                          autoFocus={true}
                        />
                      </div>
                      <div className="col-md-2 py-2">
                        {searchQuery && (
                          <i
                            className="fas fa-times"
                            onClick={clearSearch}
                            style={{ cursor: 'pointer' }}
                            aria-label="Clear search"
                            role="button"
                          ></i>
                        )}
                      </div>
                    </div>
                    <Input
                      type="select"
                      className="form-control"
                      value={statusFilter}
                      onChange={e => setStatusFilter(e.target.value)}
                      style={{
                        width: "100px",
                      }}
                    >
                      <option value="All">All</option>
                      <option value="open">Open</option>
                      {/* <option value="closed">Closed</option> */}
                    </Input>
                  </div>

                  <button
                    onClick={toggle}
                    className="btn btn-primary me-1"
                    style={{
                      color: "white",
                      fontWeight: "500",
                      padding: "10px 18px",
                      fontSize: "0.8rem",
                    }}
                    onMouseOver={e => {
                      e.target.style.backgroundColor = "#80A5FF"
                      e.target.style.color = "white"
                    }}
                    onMouseOut={e => {
                      e.target.style.backgroundColor = "#556ee6"
                      e.target.style.color = "white"
                    }}
                  >
                    <i className="fas fa-plus mx-1 text-white"></i>
                    Add New Job
                  </button>
                </div>
              </>
            )}
            {isLoading ? (
              <Loader loaderColor={"text-dark"} />
            ) : (

              <JobTableContainer
                columns={columns}
                data={transformedData}
                currentPage={page}
                setCurrentPage={setPage}
                currentPageSize={pageSize}
                setCurrentPageSize={setPageSize}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                totalPages={totalPages}
                totalRecords={totalRecords}
                addNewJobModal={addNewJobModal}
                setAddNewJobModal={setAddNewJobModal}
                setRefreshData={setRefreshData}
                className="custom-header-css"
                totalJobsCount={totalJobsCount}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default JobsTable
