import React, { useState } from "react"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  ButtonGroup,
  UncontrolledTooltip,
  Card,
  CardBody,
  Badge,
  InputGroup,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { ShareLinkModal } from "./ShareLinkModal"
import AddNewJobModal from "./AddNewJobModal"
import nojobsimage from "../../assets/images/no-jobs.svg"
import axiosInstance from "service/axiosConfig"
import Swal from "sweetalert2";

const JobTableContainer = ({
  columns,
  data,
  searchQuery,
  setSearchQuery,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  statusFilter,
  setStatusFilter,
  currentPage,
  setCurrentPage,
  currentPageSize,
  setCurrentPageSize,
  totalPages,
  totalRecords,
  addNewJobModal,
  setAddNewJobModal,
  setRefreshData,
  totalJobsCount,
}) => {
  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
    setCurrentPage(1)
  }

  const navigate = useNavigate()

  const [sharableModalLink, SetSharableModalLink] = useState("")
  const [openSharableModal, SetOpenSharableModal] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    textAlign: 'center',
    // backgroundColor: isHovered ? '#556ee6' : '#D4DBF9',
    border: 'none',
    color: isHovered ? '#545AFB' : '#000000',
    fontSize: '15px',
  };

  const toggle = () => {
    setAddNewJobModal(!addNewJobModal)
  }

  const displaySuccessMessage = () => {
    // console.log("Displaying success message")
    setShowSuccessMessage(true)
    setTimeout(() => {
      setShowSuccessMessage(false)
    }, 5000)
  }

  const formatDate = dateString => {
    const date = new Date(dateString)
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  const statusClassMap = {
    open: "bg-success badge bg-secondary",
    closed: "bg-danger badge bg-secondary",
  }

  const updateSorting = newSortBy => {
    // console.log("In update sorting: ", newSortBy)
    const actualSortBy = newSortBy === "title" ? "id" : newSortBy

    if (actualSortBy === sortBy) {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"))
    } else if (
      actualSortBy === "candidate_count" ||
      actualSortBy === "created_at"
    ) {
      // console.log("Sorting now: ", actualSortBy)
      setSortBy(actualSortBy)
      setSortOrder("asc")
    }
  }

    const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this Job?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/jobs/delete_job/${id}`)
          .then(response => {
            setRefreshData(prevState => !prevState);
          })
          .catch(error => {
            console.error("Error deleting job:", error);
          });
      }
    });
  };

  const renderSortIcon = col => {
    const [ascendingOrderStyle, SetAscendingOrderStyle] =
      useState("text-primary")
    const [descendingOrderStyle, SetDescendingOrderStyle] =
      useState("text-secondary")

    const sortAscending = column => {
      const actualColumn = column === "title" ? "id" : column
      SetAscendingOrderStyle("text-primary")
      SetDescendingOrderStyle("text-secondary")
      setSortBy(actualColumn)
      setSortOrder("asc")
    }

    const sortDescending = column => {
      const actualColumn = column === "title" ? "id" : column
      SetAscendingOrderStyle("text-secondary")
      SetDescendingOrderStyle("text-primary")
      setSortBy(actualColumn)
      setSortOrder("desc")
    }

    if (col === "candidate_count" || col === "created_at") {
      const activeColor = "black" // or whatever color you want for the active arrow
      const inactiveColor = "#aaa" // or a light gray color for inactive arrows

      const ascArrowStyle = {
        cursor: "pointer",
        fontSize: "0.7rem",
        color:
          sortOrder === "asc" && col === sortBy ? activeColor : inactiveColor,
        lineHeight: "0.9",
      }

      const descArrowStyle = {
        cursor: "pointer",
        fontSize: "0.7rem",
        color:
          sortOrder === "desc" && col === sortBy ? activeColor : inactiveColor,
        lineHeight: "0.9",
      }

      return (
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "5px",
          }}
        >
          <div
            style={ascArrowStyle}
            className={`${ascendingOrderStyle}`}
            onClick={() => sortAscending(col)}
          >
            ▲
          </div>
          <div
            style={descArrowStyle}
            className={`${descendingOrderStyle}`}
            onClick={() => sortDescending(col)}
          >
            ▼
          </div>
        </div>
      )
    }
    return null
  }

  const toggle_sharable_modal = guid => {
    SetOpenSharableModal(!openSharableModal)
    SetSharableModalLink(guid)
  }
  return (
    <div className="table-container">
      {/*{toggle && (*/}
      {/*  <AddNewJobModal*/}
      {/*    toggle={toggle}*/}
      {/*    displaySuccessMessage={displaySuccessMessage}*/}
      {/*    modal={addNewJobModal}*/}
      {/*  />*/}
      {/*)}*/}
      {showSuccessMessage && (
        <div className="alert alert-success">Job created successfully!</div>
      )}
      {/*{(data.length > 0 || searchQuery) && (*/}
      {/*  <>*/}
      {/*    <Row className="mb-4">*/}
      {/*      <Col xs="12" md="6" className="d-flex align-items-center">*/}
      {/*        <h2 style={{ fontWeight: "bold" }}>Jobs</h2>*/}
      {/*        <h3 style={{ fontWeight: "bold" }} className="mx-2">*/}
      {/*          <Badge color="light">{totalRecords}</Badge>*/}
      {/*        </h3>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}

      {/*    <div*/}
      {/*      className="d-flex gap-2"*/}
      {/*      style={{*/}
      {/*        height: "40px",*/}
      {/*        justifyContent: "space-between"*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <div className="d-flex gap-2">*/}
      {/*        <Input*/}
      {/*          type="text"*/}
      {/*          className="form-control"*/}
      {/*          placeholder="Search..."*/}
      {/*          value={searchQuery}*/}
      {/*          onChange={handleSearchChange}*/}
      {/*          style={{*/}
      {/*            width: "250px",*/}
      {/*          }}*/}
      {/*          autoFocus={true}*/}
      {/*        />*/}
      {/*        <Input*/}
      {/*          type="select"*/}
      {/*          className="form-control"*/}
      {/*          value={statusFilter}*/}
      {/*          onChange={e => setStatusFilter(e.target.value)}*/}
      {/*          style={{*/}
      {/*            width: "100px",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <option value="All">All</option>*/}
      {/*          <option value="open">Open</option>*/}
      {/*          /!* <option value="closed">Closed</option> *!/*/}
      {/*        </Input>*/}
      {/*      </div>*/}

      {/*      <button*/}
      {/*        onClick={toggle}*/}
      {/*        className="btn btn-primary me-1"*/}
      {/*        style={{*/}
      {/*          color: "white",*/}
      {/*          fontWeight: "500",*/}
      {/*          padding: "10px 18px",*/}
      {/*          fontSize: "0.8rem",*/}
      {/*        }}*/}
      {/*        onMouseOver={e => {*/}
      {/*          e.target.style.backgroundColor = "#80A5FF"*/}
      {/*          e.target.style.color = "white"*/}
      {/*        }}*/}
      {/*        onMouseOut={e => {*/}
      {/*          e.target.style.backgroundColor = "#556ee6"*/}
      {/*          e.target.style.color = "white"*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <i className="fas fa-plus mx-1 text-white"></i>*/}
      {/*        Add New Job*/}
      {/*      </button>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}

      {data.length === 0 && searchQuery.length > 0 && (
        <div className="text-center mt-5">
          <h4>No Jobs Found</h4>
        </div>
      )}

      {totalJobsCount === 0 && (
        <Row>
          <Col lg="12">
            <div className="text-center mt-5">
              <img src={nojobsimage} />
            </div>
          </Col>
          <Col lg="12" className="text-center mt-3">
            <h4>You have not created any Jobs yet.</h4>
          </Col>
          <Col lg="12" className="text-center mt-1">
            <p>
              Your first job listing awaits! Begin the journey by adding a job
              title and description, and watch it unfold with the right talent.
            </p>
          </Col>
          <Col lg="12" className="text-center mt-1">
            <button
              onClick={toggle}
              className="btn btn-primary me-1"
              style={{
                color: "white",
                fontWeight: "500",
                padding: "10px 18px",
                fontSize: "0.8rem",
                transition: "all 0.3s ease",
              }}
              onMouseOver={e => {
                e.target.style.backgroundColor = "#80A5FF"
                e.target.style.color = "white"
              }}
              onMouseOut={e => {
                e.target.style.backgroundColor = "#556ee6"
                e.target.style.color = "white"
              }}
            >
              <i className="fas fa-plus mx-1 text-white"></i>
              Create Job
            </button>
          </Col>
        </Row>
      )}

      {data.length > 0 && (
        <div
          className=""
          style={{
            minHeight: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <ShareLinkModal
            modal={openSharableModal}
            link={`${window.location.origin}/job_application/${sharableModalLink}`}
            toggle={toggle_sharable_modal}
            job={data.filter(job => job.id === sharableModalLink)[0]}
          />
          <div
            className="table-responsive"
            style={{
              minHeight: "50vh",
            }}
          >
          <Table className="table-bordered align-middle nowrap mt-2">
            <thead className="table-light">
              <tr>
                {columns.map(column => (
                  <th
                    key={column.Header}
                    className="text-center font-weight-bold p-1"
                    style={{ fontSize: "1rem" }}
                    // onClick={() => updateSorting(column.accessor)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {column.Header} {renderSortIcon(column.accessor)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={row.id} className="text-center">
                  <td className="p-1" style={{width: "500px", textAlign: "left"}}>
                    <style>
                      {`
                        .hyperlink-style {
                          color: #495057; /* Change this to the desired default color */
                          /* Additional styles can be added here */
                        }
                        .hyperlink-style:hover {
                          color: blue; /* Change this to the desired color on hover */
                          /* Additional styles can be added here for hover effect */
                        }
                       `}
                    </style>
                    <a
                        className="hyperlink-style"
                        onClick={() => {
                          navigate(`/view_candidates/${row.id}`, {
                            state: {jobTitle: row.title},
                          });
                        }}
                    >
                      {row.title}
                    </a>
                  </td>
                  <td className="p-1" style={{width: "200px"}}>{row.resumeCount}</td>
                  <td className="p-1" style={{width: "200px", textAlign: "left"}}>
                    {formatDate(row.createdAt)}
                  </td>
                  <td className="p-1">
                    <span
                        className={statusClassMap[row.status]}
                        style={{textTransform: "capitalize"}}
                    >
                      {row.status}
                    </span>
                  </td>
                  <td className="p-1">
                    <ButtonGroup className="gap-1">
                      {/* View Button */}
                      <i
                        className="btn btn-md btn-soft-info mdi mdi-eye-outline"
                        id="viewtooltip2" // Changed ID to make it unique
                        onClick={() => {
                          navigate(`/view_candidates/${row.id}`, {
                            state: { jobTitle: row.title },
                          })
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="viewtooltip2"
                      >
                        View
                      </UncontrolledTooltip>

                      {/* Share Button */}
                      <i
                        className="btn btn-md btn-soft-success mdi mdi-share-variant"
                        id="sharetooltip"
                        onClick={() => {
                          toggle_sharable_modal(row.id)
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="sharetooltip"
                      >
                        Share
                      </UncontrolledTooltip>

                      {/* Delete Button */}
                      <i
                        className="btn btn-md btn-soft-danger mdi mdi-delete" // Added btn-danger for red color
                        style={{ color: "inherit" }}
                        id="deletetooltip"
                        onClick={() => handleDelete(row.id)}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
          <Row className="justify-content-md-end justify-content-center align-items-center mb-4">
            <Col>
              <Input
                className="form-control"
                type="select"
                value={currentPageSize}
                onChange={e => setCurrentPageSize(Number(e.target.value))}
                style={{
                  width: "100px",
                }}
              >
                {[10, 15, 20, 25].map(size => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </Input>
            </Col>
            <Col className="col-ml-auto d-flex align-items-center">
              <div style={{ fontSize: "0.9rem" }}>
                Showing page <strong>{currentPage}</strong> of{" "}
                <strong>{totalPages}</strong>
              </div>
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  className="rounded-circle"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: currentPage === 1 ? "#E0E0E0" : "",
                    borderColor: "transparent",
                  }}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto">
              <Button
                className="rounded-circle"
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#3498db", // Set background color to blue
                  borderColor: "transparent",
                  color: "#fff", // Set font color to white
                }}
                disabled
              >
                {currentPage}
              </Button>
            </Col>
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  className="rounded-circle"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor:
                      currentPage === totalPages ? "#E0E0E0" : "",
                    borderColor: "transparent",
                  }}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {">"}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

export default JobTableContainer
