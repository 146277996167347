import React, { useEffect, useState } from 'react';

function NotificationComponent({ setIsNotified }) {
    const [messages, setMessages] = useState([]);

    useEffect(() => {

        const user = JSON.parse(localStorage.getItem('authUser'));
        // const wsDomain = process.env.REACT_APP_WEBSOCKET_DOMAIN;
        const wsDomain = 'wss://kiru-backend-master-6atpg5jc5q-wx.a.run.app';
        const tenant_id = user.user.tenant
        console.log(tenant_id)
        const wsUrl = `${wsDomain}/${tenant_id}/`;

        const ws = new WebSocket(wsUrl);
        // const ws = new WebSocket('ws://127.0.0.1:8000/ws/real-time-notification/${user.user.guid}/');

        ws.onopen = () => {
            console.log('WebSocket Connected');
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log(data)
            console.log(data.message)
            setMessages(prevMessages => [...prevMessages, data.message]);
            setIsNotified(true)
        };

        ws.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        ws.onclose = () => {
            console.log('WebSocket Disconnected');
        };

        return () => {
            ws.close();
        };
    }, []);

    return null;
}

export default NotificationComponent;
