import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "./Breadcrumb"
import axiosInstance from "service/axiosConfig"

const JobApplicationAuthenticatedModal = ({
  toggle,
  modal,
  setIsResumesUploaded,
}) => {
  //meta title
  document.title = "Apply for Job | R24"
  const params = useParams()
  const [data, setData] = useState({})
  const history = useNavigate()
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { job_id } = params
    axiosInstance
      .get(`jobs/get_job/${job_id}`)
      .then(response => {
        if (response.response?.status >= 400) {
          history("/page404")
        } else {
          setData(response.data)
        }
      })
      .catch(error => {
        console.log("there was an error", error)
      })
  }, [])

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    const acceptedFileTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ]

    const imageFileTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "image/webp",
      // ... add any other image types you want to restrict
    ]

    let invalidFile = false

    files.map(file => {
      if (acceptedFileTypes.includes(file.type)) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      } else if (imageFileTypes.includes(file.type)) {
        invalidFile = true
      }
    })

    if (invalidFile) {
      alert(
        "Image files are not allowed. Please upload a valid file (PDF, DOCX, or TXT)"
      )
      return // Exit the function early
    }

    if (!files.length) {
      alert("Please upload a valid file (PDF, DOCX, or TXT)")
      return
    }

    setselectedFiles(prevFiles => [...prevFiles, ...files])
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const removeFile = index => {
    const arr = [...selectedFiles]
    let indexesToRemove = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === index) {
        indexesToRemove.push(i);
      }
    }
    for (let i = indexesToRemove.length - 1; i >= 0; i--) {
      arr.splice(indexesToRemove[i], 1);
    }
    setselectedFiles(arr)
  }
  const [completionPercentage, setCompletionPercentage] = useState(0);

  async function delay() {
    console.log("Start");
    await new Promise(resolve => setTimeout(resolve, 4000)); // Wait for n seconds
    console.log("End");
  }

  const applyJob = async () => {
    setIsLoading(true);
    const { job_id } = params;
    const chunkSize = 50;
    const formDataArray = [];
    let count = 0;

    // Create chunks of FormData
    for (let i = 0; i < selectedFiles.length; i += chunkSize) {
      console.log('Index: ', i);
      const chunk = selectedFiles.slice(i, i + chunkSize);
      const formData = new FormData();
      for (let j = 0; j < chunk.length; j++) {
        console.log('Total Count:', count);
        count += 1;
        const file = chunk[j];
        if (file) {
          formData.append("applied_job[]", job_id); // Append job_id for each file
          formData.append("resume[]", file, file.name); // Append file
        } else {
          alert("Enter a valid PDF file");
          return; // Exit function if invalid file
        }
      }
      const currPercentage = (i / selectedFiles.length) * 100;
      setCompletionPercentage(currPercentage);

      axiosInstance.post("/jobs/add_bulk_candidates/", formData);
      // formDataArray.push(formData);
      await delay();
      toggle();
    }

    try {
      // Send requests for each chunk of FormData concurrently
      //   const uploadPromises = formDataArray.map(async (formData, index) => {
      //     return axiosInstance.post("/jobs/add_bulk_candidates/", formData);
      // });

      setIsResumesUploaded(true);
      setIsLoading(false);
      setCompletionPercentage(0); // Reset completionPercentage after completion
      setselectedFiles([]);
      toggle();
    } catch (error) {
      console.log("Error: ", error);
      setIsLoading(false);
    }
  };




  const progressBarStyle = {
    height: '25px',
    borderRadius: '5px',
    overflow: 'hidden',
    backgroundColor: '#f8f9fa',
  };

  const progressStyle = {
    width: `${completionPercentage}%`,
    height: '100%',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    transition: 'width 0.3s ease',
  };
  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalBody>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Jobs" breadcrumbItem={data?.title} />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Container>
                      {/* <Label className="mb-4">{data?.job_description}</Label>
                      <br />
                      <br /> */}

                      <Row className="mb-4">
                        <Col lg="10" className="offset-lg-1">
                          <Label className="col-form-label">
                            Upload your Resumes here
                          </Label>

                          <Form>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop a file here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <i
                                            className="fa fa-file-pdf-o mr-2"
                                            style={{
                                              color: "red",
                                              fontSize: "24px",
                                            }}
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {/* PDF icon added here */}
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>

                                        <Col className="col-auto">
                                          <button
                                            type="button"
                                            onClick={() => removeFile(f.name)}
                                            style={{
                                              backgroundColor: "transparent",
                                              border: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-times"
                                              style={{
                                                // color: "red",
                                                fontSize: "15px",
                                              }}
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </Form>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg="11" className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            color="primary"
                            onClick={applyJob}
                            disabled={isLoading} // Disable button when loading
                          >
                            {isLoading ? (
                              <>
                                <p>Uploading...</p>
                                <div>
                                  <progress value={completionPercentage} max="100"></progress>
                                </div>
                              </>
                            ) : (
                              <p>Upload Resumes</p>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default JobApplicationAuthenticatedModal
